const menu = {
    profile: 'Perfil',
    language: 'Linguagem',
    appearance: {
        label: 'Aparência',
        light: 'Claro',
        dark: 'Escuro',
        system: 'Sincronizar com o sistemam',
    },
    sign_out: 'Terminar sessão',
};
export default Object.freeze(menu);
