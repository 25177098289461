const guide = {
    start_building: 'Começar a construir',
    get_started: 'Começar',
    categories: {
        featured: 'Popular e para ti',
        Traditional: 'Aplicação web tradicional',
        SPA: 'Aplicação de página única',
        Native: 'Nativo',
        MachineToMachine: 'Máquina-a-máquina',
        Protected: 'Integração não-SDK',
        ThirdParty: 'Aplicação de terceiros',
    },
    filter: {
        title: 'Filtrar framework',
        placeholder: 'Procurar framework',
    },
    checkout_tutorial: 'Ver tutorial de {{name}}',
    do_not_need_tutorial: 'Se não precisares de um tutorial, podes continuar sem um guia de framework',
    finish_and_done: 'Terminar e concluir',
    cannot_find_guide: 'Não encontras o teu guia?',
    describe_guide_looking_for: 'Descreve o guia que procuras',
    request_guide_successfully: 'O teu pedido foi enviado com sucesso. Obrigado!',
    app: {
        select_framework_or_tutorial: 'Seleciona um framework ou tutorial',
        guide_modal_title: 'Começar com SDK e guias',
        modal_subtitle: 'Inicia o processo de desenvolvimento da tua aplicação com o nosso SDK pré-construído e tutoriais.',
        select_a_framework: 'Seleciona um framework',
        continue_without_framework: 'Criar aplicação sem framework',
        describe_guide_looking_for_placeholder: 'Por exemplo, quero integrar o Logto na minha aplicação Angular.',
    },
    api: {
        modal_title: 'Começar com tutoriais',
        modal_subtitle: 'Inicia o processo de desenvolvimento da tua aplicação com os nossos tutoriais pré-construídos.',
        select_a_tutorial: 'Seleciona um tutorial',
        continue_without_tutorial: 'Continuar sem tutorial',
        describe_guide_looking_for_placeholder: 'Por exemplo, quero proteger a minha API usando Deno.',
    },
};
export default Object.freeze(guide);
