const verification_code = {
    phone_email_empty: 'Tanto o telefone como o e-mail estão vazios.',
    not_found: 'Código de verificação não encontrado. Por favor, envie primeiro o código de verificação.',
    phone_mismatch: 'Telefone não corresponde. Por favor, solicite um novo código de verificação.',
    email_mismatch: 'E-mail não corresponde. Por favor, solicite um novo código de verificação.',
    code_mismatch: 'Código de verificação inválido.',
    expired: 'O código de verificação expirou. Por favor, solicite um novo código de verificação.',
    exceed_max_try: 'Limite máximo de tentativas de verificação excedido. Por favor, solicite um novo código de verificação.',
};
export default Object.freeze(verification_code);
