const invitation = {
    find_your_tenants: 'Encontre os seus inquilinos',
    find_tenants_description: 'O seu endereço de email pode já estar registado em vários inquilinos. Pode optar por juntar-se aos existentes ou continuar a criar um novo.',
    create_new_tenant: 'Criar um novo inquilino',
    email_not_match_title: 'Atualmente iniciou sessão como\n{{email}}',
    email_not_match_description: 'Por favor inicie sessão com a conta correta para aceitar o convite e tornar-se membro da organização.',
    switch_account: 'Iniciar sessão com outra conta',
    invalid_invitation_status: 'Convite inválido. Por favor contacte o administrador e tente novamente.',
    invitation_not_found: 'Convite não encontrado. Por favor contacte o administrador.',
};
export default Object.freeze(invitation);
