const tabs = {
    get_started: 'Começo',
    dashboard: 'Painel',
    applications: 'Aplicações',
    api_resources: 'Recursos API',
    sign_in_experience: 'Experiência de login',
    connectors: 'Conectores',
    enterprise_sso: 'SSO empresarial',
    webhooks: 'Webhooks',
    organizations: 'Organizações',
    users: 'Gestão de utilizadores',
    audit_logs: 'Registos de auditoria',
    roles: 'Funções',
    docs: 'Documentação',
    tenant_settings: 'Definições do inquilino',
    mfa: 'Autenticação multi-fator',
    customize_jwt: 'JWT personalizado',
    signing_keys: 'Chaves de assinatura',
    organization_template: 'Modelo de organização',
};
export default Object.freeze(tabs);
