const general = {
    placeholder: 'Espaço reservado',
    submit: 'Submeter',
    skip: 'Saltar',
    next: 'Seguinte',
    back: 'Voltar',
    retry: 'Tentar novamente',
    done: 'Concluído',
    search: 'Pesquisar',
    search_placeholder: 'Pesquisar',
    clear_result: 'Limpar resultados',
    save: 'Guardar',
    save_changes: 'Guardar alterações',
    saved: 'Guardado',
    discard: 'Descartar',
    loading: 'A carregar...',
    redirecting: 'A redirecionar...',
    add: 'Adicionar',
    added: 'Adicionado',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    check_out: 'Finalizar',
    create: 'Criar',
    set_up: 'Configurar',
    customize: 'Personalizar',
    enable: 'Ativar',
    reminder: 'Lembrete',
    edit: 'Editar',
    delete: 'Eliminar',
    deleted: 'Deleted',
    more_options: 'MAIS OPÇÕES',
    close: 'Fechar',
    copy: 'Copiar',
    copying: 'A copiar',
    copied: 'Copiado',
    required: 'Obrigatório',
    add_another: 'Adicionar outro',
    create_another: 'Criar outro',
    deletion_confirmation: 'Tem a certeza que deseja eliminar isso {{title}}?',
    settings_nav: 'Definições',
    unsaved_changes_warning: 'Fez algumas alterações. Tem a certeza que deseja sair desta página?',
    leave_page: 'Sair da página',
    stay_on_page: 'Permanecer na página',
    type_to_search: 'Digite para pesquisar',
    got_it: 'Entendi',
    continue: 'Continuar',
    page_info: '{{min, number}}-{{max, number}} de {{total, number}}',
    learn_more: 'Saber mais',
    /** UNTRANSLATED */
    tab_error_one: '{{count, number}} error',
    tab_error_other: '{{count, number}} erros',
    skip_for_now: 'Saltar por agora',
    remove: 'Remover',
    visit: 'Visitar',
    join: 'Participar',
    try_now: 'Experimente agora',
    multiple_form_field: '(Múltiplo)',
    demo: 'Demonstração',
    unnamed: 'Sem nome',
    view: 'Ver',
    open: 'Abrir',
    hide: 'Esconder',
    unknown_error: 'Erro desconhecido, por favor tente novamente mais tarde.',
    select: 'Selecionar',
    contact_us_action: 'Contacte-nos',
    description: 'Descrição',
    name: 'Nome',
    add_field: 'Adicionar {{field}}',
    create_field: 'Criar {{field}}',
    edit_field: 'Editar {{field}}',
    delete_field: 'Eliminar {{field}}',
    coming_soon: 'Em breve',
    or: 'Ou',
};
export default Object.freeze(general);
