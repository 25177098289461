const permissions = {
    search_placeholder: 'Pesquisar por nome de API ou permissão',
    search_placeholder_without_api: 'Pesquisar por nome de permissão',
    name_column: 'Permissão',
    description_column: 'Descrição',
    api_column: 'API',
    placeholder_title: 'Permissão',
    placeholder_description: 'Permissão refere-se à autorização para acessar um recurso (que chamamos de recurso da API).',
    edit: 'Permissão de edição',
    delete: 'Permissão de eliminação',
    remove: 'Permissão para remover',
    edit_title: 'Editar permissão da API',
};
export default Object.freeze(permissions);
