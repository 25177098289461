const get_started = {
    page_title: 'Começar',
    title: 'Algo para explorar para o ajudar a ter sucesso',
    subtitle: 'Algumas coisas que você pode fazer para obter rapidamente valor do Logto',
    develop: {
        title: 'Desenvolver: Dedique 5 minutos para integrar o seu aplicativo',
        title_cloud: 'Desenvolver: Integre o seu aplicativo de forma segura em minutos',
        subtitle_cloud: 'Ou leve 5 minutos para integrar o seu aplicativo com os nossos SDKs predefinidos e tutoriais.',
    },
    customize: {
        title: 'Personalizar: Ofereça uma ótima experiência de login',
        preview: {
            title: 'Verifique a pré-visualização ao vivo da experiência de login que você acabou de personalizar',
            subtitle: 'Experimente agora a experiência de login do Logto para ver como funciona',
        },
        connector: {
            title: 'Adicione mais conectores para suportar mais métodos de login social',
            subtitle: 'Experimente o login sem senha e ofereça uma experiência segura e sem atritos para seus clientes',
        },
        continue_customizing: 'Continue personalizando',
        try_now: 'Experimente agora',
        add_more: 'Adicione mais',
    },
    secure: {
        title: 'Segurança: Proteja seus recursos',
    },
    manage: {
        title: 'Gerenciar: Defina o controle de acesso para seu produto e usuários',
        rbac: {
            title: 'Adicione controle de acesso baseado em função para proteger seus recursos',
            subtitle: 'Controle seu recurso por meio de autorização de função escalável para diversos casos de uso.',
        },
        create_roles: 'Criar funções',
    },
    view_all: 'Ver tudo →',
};
export default Object.freeze(get_started);
